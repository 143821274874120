import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private storageKey = 'pickupLocation';

  savePickupLocation(location: any): void {
    localStorage.setItem(this.storageKey, location);
  }

  getPickupLocation(): any | null {
    return localStorage.getItem(this.storageKey);
  }

  clearPickupLocation(): void {
    localStorage.removeItem(this.storageKey);
  }
}
